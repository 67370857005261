import React from "react"

const Card = ({src, title, url}) => {
  const handleClick = () => {
    window.open(url, "_blank")
  }
  if (!url) return ""
  return (
    <div className="card-content" onClick={handleClick}>
      <img src={src} className="icon" />
      <span className="sec-title">{title}</span>
    </div>
  )
}

export default Card
